import React from 'react'

import BasicButton from '../BasicButton/BasicButton'
import InputComponent from '../InputComponent/InputComponent'
import { XMarkIcon } from '@heroicons/react/24/outline'

// Define an interface for the component's props
type PopupComponentProps = {
    children: React.ReactNode
    setOpen: (open: boolean) => void
    open: boolean
    closeButton?: boolean
    className?: string
    buttonClassName?: string
    modalClassName?: string
    buttonIcon?: React.ReactNode
}

const PopupComponent = ({
    children,
    setOpen,
    open,
    closeButton = true,
    className = 'relative flex flex-col justify-center z-40 min-h-[120px] max-sm:p-2 modal-box animate-fade-up animate-duration-300 animate-once animate-ease-out bg-gray-100 dark:bg-base-100',
    buttonClassName,
    modalClassName,
    buttonIcon,
}: PopupComponentProps) => {
    const buttonDefaultClassName =
        'absolute z-50 cursor-default btn btn-neutral btn-sm btn-circle top-4 right-4'

    const defaultModalClassName = 'modal backdrop-blur-sm z-[999999]'

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
            }}
        >
            <InputComponent
                type="checkbox"
                readOnly
                checked={open}
                id="my-modal-5"
                className="modal-toggle"
            />
            <div className={modalClassName || defaultModalClassName}>
                <div className={className} onClick={(e) => e.stopPropagation()}>
                    {closeButton ? (
                        <BasicButton
                            className={buttonClassName || buttonDefaultClassName}
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpen(!open)
                            }}
                        >
                            {buttonIcon || (
                                <XMarkIcon className="w-6 h-6" fill="none" strokeWidth={2} />
                            )}
                        </BasicButton>
                    ) : null}

                    {children}
                </div>
            </div>
        </div>
    )
}

export default PopupComponent
