import React from 'react'

type GoogleIframeMapComponentPropTypes = {
    iframeSrc: string
    width: string | number
    height: string | number
    className: string
}
const GoogleIframeMapComponent = ({
    iframeSrc,
    width = '600',
    height = '450',
    className = 'border rounded-lg shadow border-opacity-10 border-slate-400 animate-fade animate-once animate-duration-250',
}: GoogleIframeMapComponentPropTypes) => {
    return (
        <div className="flex justify-center">
            <iframe
                title="Location"
                className={className}
                src={iframeSrc}
                width={width}
                height={height}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    )
}

export default GoogleIframeMapComponent
