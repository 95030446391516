import React from 'react'

import { StarIcon } from '@heroicons/react/24/outline'

type EventTypeComponentPropTypes = {
    eventCount: number
    containerClassName?: string
}

const EventTypeComponent = ({
    eventCount,
    containerClassName = `absolute right-0.5 z-20 flex ${eventCount > 1 ? '' : 'max-sm:w-4/12 md:!w-3/12'} max-sm:w-5/12 md:w-4/12 gap-0.5 bg-opacity-70 top-2 max-sm:top-0.5 badge badge-neutral p-0  border-opacity-30 border-slate-500 drop-shadow-md border-0`,
}: EventTypeComponentPropTypes) => {
    return (
        <div className={containerClassName}>
            {eventCount > 1 ? (
                <div className="flex my-auto transition-opacity">
                    <span className="my-auto text-xs font-semibold">{eventCount}</span>
                </div>
            ) : null}
            <StarIcon
                className={` right-0 w-4 h-4 rounded-full !stroke-current max-sm:w-3 max-sm:h-3 text-current ${eventCount > 1 ? 'max-sm:hidden' : ''}`}
                strokeWidth={2}
            />
        </div>
    )
}

export default EventTypeComponent
