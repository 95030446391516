import React, { useEffect, useState } from 'react'

import { getEventsByDateRange } from '@/services/BookingServiceAPI'

/* Api */
import { getVenueInformation } from '@/services/VenueService'
import { addDays, addMonths, endOfDay, lastDayOfMonth, startOfMonth } from 'date-fns'

import { createServerClient, serializeCookieHeader } from '@supabase/ssr'

import { GetServerSidePropsContext } from 'next'

/* Hooks */
import { useGetTranslations } from '@/i18n/index.tsx'

/* Context */
import { useApplicationContext } from '@/context/ApplicationContext.tsx'

/* Constants */
import { NOTIFICATION_TYPE } from '@/constants/constants.ts'

/* Utils */
import { findNextAvailableBookingDay, formatDate } from '@/utils/calendarUtils.ts'
import { getDefaultVenueSettings } from '@/utils/typeGuardUtils'

import { useDefer } from '@/hooks/useDefer.ts'
import useFetchOnFocus from '@/hooks/useFetchOnFocus'

/* API Hooks */
import useBookingServiceApi from '../hooks/api/useBookingServiceApi'

import VenueInformationComponent from '@/components/AdminDashboard/VenueInformationComponent/VenueInformationComponent.tsx'
import BookingComponent from '@/components/BookingComponent/BookingComponent.tsx'

/* Components */
import CustomerLayout from '@/layouts/CustomerLayout.tsx'

import { ExtendedEventsT, ExtendedVenueSettingsT } from '@/types/globalTypes'

const BookingPage = ({
    venue,
    serverEvents,
}: {
    venue: ExtendedVenueSettingsT
    serverEvents: ExtendedEventsT[]
}) => {
    const { getEventsByDateRange } = useBookingServiceApi()

    const { setToastNotification, applicationState } = useApplicationContext()
    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()
    const selectedLanguage = applicationState.selectedLanguage
    const venueId = applicationState?.venue?.id

    const {
        working_hours,
        reservation_lead_time,
        max_calendar_days,
        days_of_operation,
        name,
        logo_url,
        description,
        map_url,
        address,
        phone_number,
        email,
        website,
        instagram_link,
        linkedin_link,
        tiktok_link,
        youtube_link,
        facebook_link,
        allow_reservations,
    } = applicationState?.venue

    const [initializePageLoad, setInitializePageLoad] = useState(true)
    const [dateToday, setDateToday] = useState(new Date())

    const [selectedDate, setSelectedDate] = useState(() => {
        // If the current day is active and the current time is at least before the reservation_lead_time aka if the lead time is 1 hour before the starting time,
        // return the current day as the selected date. Otherwise, find the next available day.
        return findNextAvailableBookingDay(dateToday, working_hours, reservation_lead_time)
    })

    const dayOfWeek = new Date(selectedDate).getDay()

    const { starting_hours, ending_hours } = working_hours[dayOfWeek] || {
        starting_hours: '00:00',
        ending_hours: '00:00',
        is_active: false,
    }

    useEffect(() => {
        // Convert starting_hours to a Date object, assuming it's for today
        const [startingHours, startingMinutes] = starting_hours.split(':').map(Number)
        const startingTimeToday = new Date()
        startingTimeToday.setHours(startingHours, startingMinutes, 0, 0)

        // Subtract 1 hour to get the reevaluation time
        const reevaluationTime = new Date(startingTimeToday.getTime() - 60 * 60 * 1000)

        // If reevaluationTime is in the future, set up a timeout to call reevaluateSelectedDate
        if (reevaluationTime > new Date()) {
            const delta = reevaluationTime.valueOf() - Date.now()

            const timeout = setTimeout(() => {
                setSelectedDate(
                    findNextAvailableBookingDay(dateToday, working_hours, reservation_lead_time)
                )
            }, delta)
            return () => clearTimeout(timeout) // Cleanup the timeout if the component is unmounted
        }
    }, [dateToday, reservation_lead_time, starting_hours, working_hours])

    const [activeMonth, setActiveMonth] = useState({
        startOfMonth: formatDate(startOfMonth(selectedDate)),
        endOfMonth: formatDate(lastDayOfMonth(addMonths(selectedDate, 1))),
    })

    const [minDate, setMinDate] = useState(dateToday)
    const [maxDate, setMaxDate] = useState(addDays(dateToday, max_calendar_days))

    const [events, setEvents] = useState<ExtendedEventsT[]>(serverEvents || [])

    const fetchCalendarData = async () => {
        if (!dateToday || !starting_hours || !ending_hours) {
            return undefined
        }

        const { eventsData, eventError } = await getEventsByDateRange(
            activeMonth.startOfMonth,
            activeMonth.endOfMonth,
            selectedLanguage,
            venueId
        )

        if (eventError) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            setInitializePageLoad(false)
            return
        }

        setEvents((prevEvents) => {
            // Create a new array to hold the updated events
            let updatedEvents = [...prevEvents]

            // Iterate through the newly fetched events data
            eventsData.forEach((newEvent) => {
                // Check if the new event already exists in the current events state
                const existingEventIndex = updatedEvents.findIndex(
                    (prevEvent) => prevEvent.event_id === newEvent.event_id
                )

                if (existingEventIndex !== -1) {
                    // If the event exists, update it
                    updatedEvents[existingEventIndex] = { ...newEvent, id: newEvent.event_id }
                } else {
                    // If the event doesn't exist, add it to the updated events array
                    updatedEvents.push({ ...newEvent, id: newEvent.event_id })
                }
            })

            return updatedEvents
        })

        setInitializePageLoad(false)
    }

    useFetchOnFocus(() => {
        fetchCalendarData()
    }, [
        activeMonth.endOfMonth,
        activeMonth.startOfMonth,
        dateToday,
        ending_hours,
        selectedLanguage,
        setToastNotification,
        starting_hours,
    ])

    // We defererly set a new day at the end of the current one
    useDefer(endOfDay(dateToday), () => {
        setDateToday(new Date())
    })

    return (
        <CustomerLayout initializePageLoad={initializePageLoad} isAuthorized={true}>
            <div className="animate-fade">
                <BookingComponent
                    dateToday={dateToday}
                    selectedDate={selectedDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    allow_reservations={allow_reservations}
                    days_of_operation={days_of_operation}
                    events={events}
                    setActiveMonth={setActiveMonth}
                    setSelectedDate={setSelectedDate}
                />

                <VenueInformationComponent
                    address={address}
                    description={description}
                    email={email}
                    facebook_link={facebook_link}
                    instagram_link={instagram_link}
                    linkedin_link={linkedin_link}
                    logo_url={logo_url}
                    map_url={map_url}
                    name={name}
                    phone_number={phone_number}
                    tiktok_link={tiktok_link}
                    website={website}
                    working_hours={working_hours}
                    youtube_link={youtube_link}
                />
            </div>
        </CustomerLayout>
    )
}

export async function getServerSideProps({ locale, req, res }: GetServerSidePropsContext) {
    const dateToday = new Date()

    const supabaseClient = createServerClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_KEY!,
        {
            cookies: {
                getAll() {
                    return Object.keys(req.cookies).map((name) => ({
                        name,
                        value: req.cookies[name],
                    }))
                },
                setAll(cookiesToSet) {
                    res.setHeader(
                        'Set-Cookie',
                        cookiesToSet.map(({ name, value, options }) =>
                            serializeCookieHeader(name, value, options)
                        )
                    )
                },
            },
        }
    )

    const [venue_settings, venueError] = await getVenueInformation(supabaseClient)
    const initialStartOfMonth = formatDate(startOfMonth(dateToday))
    const initialEndOfMonth = formatDate(lastDayOfMonth(addMonths(dateToday, 1)))
    console.log(venue_settings)
    // If venue settings could not be fetched, use default values
    if (venueError || !venue_settings || !venue_settings.id) {
        console.error('Error fetching venue settings or missing venue ID:', venueError)
        return {
            props: {
                venue: getDefaultVenueSettings(),
                serverEvents: [],
                locale: locale,
            },
        }
    }

    const venueId = venue_settings.id

    // Fetch events data only if venueId is available
    const [eventsData, eventError] = await getEventsByDateRange(
        supabaseClient,
        initialStartOfMonth,
        initialEndOfMonth,
        locale,
        venueId
    )


    if (eventError) {
        console.error('Error fetching events data:', eventError)
        return {
            props: {
                venue: venue_settings,
                serverEvents: [],
                locale: locale,
            },
        }
    }

    return {
        props: {
            venue: venue_settings,
            serverEvents: eventsData,
            locale: locale,
        },
    }
}

export default BookingPage
