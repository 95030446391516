import * as React from 'react'

const TikTokIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <g strokeWidth={0} />
        <g strokeLinecap="round" strokeLinejoin="round" />
        <g stroke="currentColor" strokeLinecap="round" strokeWidth={12}>
            <path d="M108 132a38.004 38.004 0 0 1-23.458 35.107 37.995 37.995 0 0 1-41.412-8.237 37.996 37.996 0 0 1-8.237-41.412A38.001 38.001 0 0 1 70 94" />
            <path strokeLinejoin="round" d="M108 132V22c0 18 24 50 52 50" />
        </g>
    </svg>
)
export default TikTokIcon
