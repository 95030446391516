import { useGetTranslations } from '@/i18n'

import { useApplicationContext } from '@/context/ApplicationContext'

import { ENUM_HORECA_VENUE_TYPES } from '@/enums/Enums'

/**
 * Determines the appropriate prefix ("Room" or "Table") based on the venue type.
 * This hook is used to adjust UI labels for consistency in different venue contexts,
 * specifically switching between "Room" for hotels and "Table" for other venues.
 */
export function useVenuePrefix() {
    const { VENUE_PREFIX_TEXT } = useGetTranslations()
    const { applicationState } = useApplicationContext()

    const venueType = applicationState.venue.venue_type

    return {
        venueType,
        venuePrefix:
            venueType === ENUM_HORECA_VENUE_TYPES.HOTEL
                ? VENUE_PREFIX_TEXT.ROOM
                : VENUE_PREFIX_TEXT.TABLE,
    }
}
