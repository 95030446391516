import React, { useCallback, useEffect, useState } from 'react'

import ImageComponent from '../ImageComponent/ImageComponent.tsx'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel, {
    EmblaCarouselType,
    EmblaOptionsType,
    UseEmblaCarouselType,
} from 'embla-carousel-react'

import { ExtendedEmblaOptionsType } from '@/types/globalTypes.js'

type CarouselComponentProps = {
    options?: ExtendedEmblaOptionsType
    images: {
        url: string
        eventName: string
    }[]
    autoPlay?: boolean
}

const CarouselComponent = ({
    options = { loop: true, align: 'center', axis: 'x' },
    images,
    autoPlay = true,
}: CarouselComponentProps) => {
    const [emblaRef, emblaApi]: UseEmblaCarouselType = useEmblaCarousel(options, [
        Autoplay({
            active: autoPlay,
            delay: options.delay,
            stopOnInteraction: true,
        }),
    ])

    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])
    const scrollTo = useCallback(
        (index: number) => emblaApi && emblaApi.scrollTo(index),
        [emblaApi]
    )

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)
        emblaApi.on('reInit', onInit)
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emblaApi, onInit, onSelect])

    return (
        <div className="embla" ref={emblaRef}>
            <div className="flex flex-row w-full h-full embla__container">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="relative flex flex-grow flex-shrink-0 w-full h-full overflow-hidden -z-20 embla__slide will-change-transform"
                    >
                        <ImageComponent
                            alt={image.url}
                            width={256}
                            height={256}
                            fill={false}
                            style={null}
                            quality={50}
                            imageUrl={image.url}
                            className="flex-grow flex-shrink-0 object-cover w-full h-full m-auto overflow-hidden duration-1000 ease-in-out will-change-transform"
                            containerClassname="flex relative"
                            loadingSpinnerClassName="absolute w-fit text-center top-[30%] left-0 right-0 m-auto z-[99999] flex-grow flex-shrink-0"
                            loadingSpinnerSvgClassName="w-5 h-5 m-auto"
                            beforeLoadingTransition="scale-105 blur-sm grayscale"
                            afterLoadingTransition="scale-100 blur-0 grayscale-0"
                        />

                        <p
                            className={`absolute mx-2 transition-all max-sm:hidden animate-delay-400 p-0 px-2 text-xs leading-5  border-0 bg-opacity-70 max-sm:top-1/3 top-1/2 badge badge-neutral border-opacity-30 border-slate-500 drop-shadow-md line-clamp-1 text-slate-50 ${selectedIndex === index ? 'animate-fade' : ''}`}
                        >
                            {image.eventName}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CarouselComponent
