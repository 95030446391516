import React from 'react'

import BasicButton from '../BasicButton/BasicButton'

import { ActionButtonsProps } from '@/types/globalTypes'

const ActionButtons = ({
    buttons,
    containerClassName,
    style,
    labelClassName,
}: ActionButtonsProps) => {
    return (
        <div className={containerClassName} style={style}>
            {buttons && buttons.length > 0
                ? buttons.map((button, idx) => {
                      if (button)
                          return (
                              <BasicButton
                                  key={`${button.label}${idx}`}
                                  type={button.type}
                                  label={button.label}
                                  onClick={button.onClick}
                                  disabled={button.disabled}
                                  className={button.className}
                                  icon={button.icon}
                                  labelClassName={button.labelClassName || labelClassName}
                              />
                          )
                  })
                : null}
        </div>
    )
}

export default ActionButtons
