import React, { useEffect, useRef, useState } from 'react'

import BasicButton from '../BasicButton/BasicButton'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import { useGetTranslations } from '@/i18n/index'

type ClampTextComponentPropTypes = {
    text: string
    maxHeight: number
    buttonClassName: string
    className: string
}

const ClampTextComponent = ({
    text,
    maxHeight,
    buttonClassName,
    className = 'w-full overflow-hidden transition-all duration-200 ease-in-out line-clamp-2 animate-duration-500',
}: ClampTextComponentPropTypes) => {
    const { CLAMP_TEXT_COMPONENT } = useGetTranslations()
    const textRef = useRef(null)
    const [textHeight, setTextHeight] = useState(0)
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        if (textRef.current) {
            const element = textRef.current
            setTextHeight(element.scrollHeight)

            const handleResize = () => {
                element.style.maxHeight = showMore ? `${element.scrollHeight}px` : `${maxHeight}px`
                if (showMore) {
                    element.classList.add('line-clamp-none')
                } else {
                    setTimeout(() => {
                        element.classList.remove('line-clamp-none')
                    }, 100)
                }
            }

            window.addEventListener('resize', handleResize)
            handleResize()

            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [maxHeight, showMore, text])

    const handleClick = () => {
        setShowMore((prev) => !prev)
    }

    return (
        <div>
            <p ref={textRef} className={className}>
                {text}
            </p>
            {textHeight > maxHeight ? (
                <BasicButton
                    className={`${buttonClassName} !text-xs flex m-1 my-auto  font-bold btn btn-ghost no-animation btn-sm hover:bg-transparent  ps-0 ms-0`}
                    onClick={handleClick}
                >
                    {showMore ? CLAMP_TEXT_COMPONENT.SHOW_LESS : CLAMP_TEXT_COMPONENT.SHOW_MORE}
                    <ChevronDownIcon
                        className={`w-3 h-3 m-auto transition-transform duration-400 transform ${showMore ? '-rotate-180' : ''}`}
                    />
                </BasicButton>
            ) : null}
        </div>
    )
}

export default ClampTextComponent
