import React, { useEffect, useRef, useState } from 'react'

/* Icons */
import { Bars3Icon } from '@heroicons/react/24/outline'

import dynamic from 'next/dynamic.js'

/* Hooks */
import { useRouter } from 'next/router.js'

import { useGetTranslations } from '@/i18n/index.tsx'

import { useApplicationContext } from '@/context/ApplicationContext.tsx'

/* Utils */
import { ENUM_PATHNAMES } from '@/constants/constants.ts'

import { ENUM_HORECA_VENUE_TYPES } from '@/enums/Enums'

import { useVenuePrefix } from '@/hooks/useVenuePrefix'
import useWindowSize from '@/hooks/useWindowSize'

import BasicButton from '@/components/shared/BasicButton/BasicButton'
import InputComponent from '@/components/shared/InputComponent/InputComponent.tsx'
import LoadingSpinner from '@/components/shared/LoadingSpinner/LoadingSpinner.tsx'
import NotAuthenticatedPageComponent from '@/components/shared/NotAuthorizedPageComponent/NotAuthorizedPageComponent.tsx'
import SEOHeadComponent from '@/components/shared/SEOHeadComponent/SEOHeadComponent.tsx'
import ThemeChanger from '@/components/shared/ThemeChanger/ThemeChanger.tsx'

/* Components */
import ToastNotificationComponent from '@/components/shared/ToastNotificationComponent/ToastNotificationComponent.tsx'

import BellIcon from '@/icons/BellIcon.tsx'

const DynamicLanguageSelectComponent = dynamic(
    () => import('@/components/shared/LanguageSelectComponent/LanguageSelectComponent.tsx'),
    {
        loading: () => <LoadingSpinner className="m-auto text-center" />,
        ssr: false,
    }
)

const DynamicNotificationBellComponent = dynamic(
    () => import('@/components/shared/NotificationBellComponent/NotificationBellComponent.tsx'),
    {
        loading: () => (
            <BellIcon strokeWidth={1.5} className="w-6 h-6 mt-2 stroke-current xl:w-7 xl:h-7" />
        ),
        ssr: false,
    }
)

type CustomerLayoutTypes = {
    children: React.ReactNode
    isAuthorized: boolean | null
    initializePageLoad: boolean
}

const CustomerLayout = ({ children, isAuthorized, initializePageLoad }: CustomerLayoutTypes) => {
    const router = useRouter()
    const { applicationState } = useApplicationContext()
    const { ROUTE_LABELS } = useGetTranslations()
    const { width } = useWindowSize()
    const { venueType } = useVenuePrefix()

    const navRef = useRef<HTMLDivElement>()
    const scrollingElementRef = useRef<HTMLDivElement>()
    const [offset, setOffset] = useState(0)
    const [showNotifications, setShowNotifications] = useState(false)
    const { name, logo_url, description } = applicationState.venue

    const handleClick = async (path: string) => {
        if (router.pathname === path) {
            return
        }
        if (router.isReady) {
            await router.push(path)
        }
    }

    useEffect(() => {
        const element = scrollingElementRef.current
        if (element && width <= 1280) {
            const onScroll = () => setOffset(element.scrollTop)
            element.addEventListener('scroll', onScroll, { passive: true })
            return () => element.removeEventListener('scroll', onScroll)
        }
    }, [width])

    useEffect(() => {
        if (navRef.current) {
            if (Number(offset) > 20 && width <= 1280) {
                navRef.current.classList.add('shadow', 'dark:shadow-lg', 'bg-base-100')
            } else {
                navRef.current.classList.remove('shadow', 'dark:shadow-lg', 'bg-base-100')
            }
        }
    }, [offset, width])

    if (initializePageLoad) {
        return (
            <div className="flex justify-center w-screen h-screen">
                <LoadingSpinner className="m-auto" />
            </div>
        )
    }

    if (isAuthorized === false && initializePageLoad === false) {
        return <NotAuthenticatedPageComponent />
    }

    return (
        <div className="overflow-y-hidden bg-gray-100 no-scrollbar h-dvh drawer animate-fade dark:bg-base-100">
            <SEOHeadComponent
                title={`${name} | Table X`}
                description={description}
                siteLogo={logo_url}
                canonicalUrl={`${process.env.NEXT_PUBLIC_FRONTEND_BASE_URL}${router.asPath}`}
                ogType="website"
                siteName={`${name} | Table X`}
            >
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `{"@context": "https://schema.org",
                    "@type": "Website",
                    "name": "${name} | Table X",
                    "headline": "${name} | Table X",
                    "description": "${description}"
                  }`,
                    }}
                />
                <meta
                    name="google-site-verification"
                    content="3CrOKIG3PKZi0dnoKWfZ0lmoSzAecs7Ixk5sIM8t4xo"
                />
                <link
                    rel="canonical"
                    href={`${process.env.NEXT_PUBLIC_FRONTEND_BASE_URL}${router.asPath}`}
                />
            </SEOHeadComponent>

            <ToastNotificationComponent />
            <InputComponent id="my-drawer-3" type="checkbox" className="drawer-toggle" />
            <div
                ref={scrollingElementRef}
                className="flex flex-col h-dvh !overflow-y-scroll drawer-content max-lg:no-scrollbar"
            >
                <div
                    ref={navRef}
                    className="justify-between text-center navbar max-sm:w-auto max-lg:w-auto xl:w-2/3 xl:mx-auto"
                >
                    <div className="flex-auto lg:hidden">
                        <label htmlFor="my-drawer-3" className="btn btn-square btn-ghost">
                            <Bars3Icon
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                className="inline-block w-6 h-6 stroke-current"
                            />
                        </label>
                    </div>
                    <div className="flex gap-2 px-2 text-xl font-bold xl:flex-1 lg:text-2xl">
                        {router.pathname === ENUM_PATHNAMES.BOOKING ? null : (
                            <DynamicNotificationBellComponent
                                showNotifications={showNotifications}
                                setShowNotifications={setShowNotifications}
                            />
                        )}
                        {applicationState.venue.name}
                    </div>
                    <div className="flex-none hidden lg:block">
                        <ul className="flex gap-2">
                            <li className="m-auto rounded">
                                {width >= 1024 ? <DynamicLanguageSelectComponent /> : null}
                            </li>
                            <li className="m-auto rounded">
                                <ThemeChanger />
                            </li>
                        </ul>
                    </div>
                </div>
                {children}
            </div>
            <div className="drawer-side z-[99998]">
                <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
                <div className="flex flex-col h-full gap-5 p-4 px-1 my-auto text-lg font-semibold bg-gray-100 w-72 max-md:gap-2 dark:bg-base-100 indent-5">
                    <div className="flex flex-col md:mt-20">
                        <ul className="flex flex-col gap-2 text-center indent-0">
                            <li className="m-1 rounded lg:hidden">
                                <ThemeChanger />
                            </li>
                            <li className="m-1 rounded lg:hidden">
                                {width < 1024 ? <DynamicLanguageSelectComponent /> : null}
                            </li>
                            <li className="m-1 text-xl rounded lg:hidden">
                                <BasicButton onClick={() => handleClick(ENUM_PATHNAMES.BOOKING)}>
                                    {venueType === ENUM_HORECA_VENUE_TYPES.HOTEL
                                        ? ROUTE_LABELS.EVENTS
                                        : ROUTE_LABELS[ENUM_PATHNAMES.BOOKING]}
                                </BasicButton>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerLayout
