import React from 'react'

import ClampTextComponent from '../../shared/ClampTextComponent/ClampTextComponent.tsx'
import GoogleIframeMapComponent from '../../shared/GoogleIframeMapComponent/GoogleIframeMapComponent.tsx'
import ImageComponent from '../../shared/ImageComponent/ImageComponent.tsx'
import {
    AtSymbolIcon,
    ClockIcon,
    GlobeAltIcon,
    MapPinIcon,
    PhoneIcon,
} from '@heroicons/react/24/outline'

import { useGetTranslations } from '@/i18n/index.tsx'

import { splitTimeStringIntoHoursAndMinutes } from '@/utils/calendarUtils.ts'

import useWindowSize from '@/hooks/useWindowSize'

import FacebookIcon from '@/icons/FacebookIcon.tsx'
import InstagramIcon from '@/icons/InstagramIcon.tsx'
import LinkedinIcon from '@/icons/LinkedinIcon.tsx'
import TikTokIcon from '@/icons/TikTokIcon.tsx'
import YouTubeIcon from '@/icons/YouTubeIcon.tsx'

import { ExtendedVenueSettingsT, VenueSettingsT, WorkingHoursT } from '@/types/globalTypes.js'

const VenueInformationComponent = ({
    name,
    logo_url,
    description,
    map_url,
    address,
    phone_number,
    email,
    website,
    instagram_link,
    linkedin_link,
    tiktok_link,
    youtube_link,
    facebook_link,
    working_hours,
}: Partial<ExtendedVenueSettingsT>) => {
    const { DAYS_IN_TEXT, BOOKING_PAGE_TEXT } = useGetTranslations()
    const { width } = useWindowSize()

    const structuredWorkingHours = Object.keys(DAYS_IN_TEXT).map((key) => ({
        day: DAYS_IN_TEXT[key],
        ...working_hours[key],
    }))

    return (
        <div className="mb-5 max-xl:m-2">
            <h2 className="my-5 text-4xl font-semibold text-center">
                {BOOKING_PAGE_TEXT.INFORMATION_HEADING}
            </h2>

            {description !== '' ? (
                <div className="flex gap-2 p-4 mx-auto border rounded-lg shadow max-sm:flex-col bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-5 border-slate-500 animate-fade text-start xl:w-10/12 2xl:w-8/12 max-xl:mb-5 xl:hidden">
                    <div className="flex flex-col gap-1 my-2">
                        <h2 className="my-2 text-xl font-semibold text-center max-xl:hidden">
                            {name}
                        </h2>
                        {logo_url ? (
                            <ImageComponent
                                alt="logo_256px"
                                quality={50}
                                fill={false}
                                width={128}
                                height={128}
                                style={null}
                                className="w-full h-full m-auto overflow-hidden duration-1000 ease-in-out rounded-lg max-w-none"
                                containerClassname="w-40 h-40 rounded-lg dark:bg-slate-50 dark:bg-opacity-5 bg-base-100 overflow-hidden flex relative mx-auto"
                                loadingSpinnerClassName="absolute w-fit text-center top-[30%] left-0 right-0 m-auto z-[99999]"
                                loadingSpinnerSvgClassName="w-4 h-4 inline"
                                beforeLoadingTransition="scale-105 blur-sm grayscale"
                                afterLoadingTransition="scale-100 blur-0 grayscale-0"
                                imageUrl={logo_url}
                            />
                        ) : null}
                    </div>

                    <ClampTextComponent
                        className="!w-full !max-w-3xl overflow-hidden prose transition-all duration-200 ease-in-out line-clamp-5 animate-duration-500"
                        buttonClassName="dark:text-slate-300 text-current"
                        maxHeight={200}
                        text={description}
                    />
                </div>
            ) : null}

            <div className="flex justify-center flex-grow-0 w-full gap-4 mx-auto max-xl:flex-col max-sm:mx-auto xl:w-10/12 animate-fade animate-once animate-duration-250">
                {description !== '' ? (
                    <div className="flex-col flex-grow-0 gap-2 p-4 border rounded-lg shadow-lg bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-5 border-slate-500 animate-fade text-start xl:w-10/12 2xl:w-4/12 max-xl:mb-5 max-xl:hidden">
                        <div className="flex flex-col gap-1 my-2">
                            <h2 className="my-2 text-xl font-semibold text-center max-xl:hidden">
                                {name}
                            </h2>
                            {logo_url ? (
                                <ImageComponent
                                    loading="eager"
                                    alt="logo_256px"
                                    quality={50}
                                    fill={false}
                                    width={128}
                                    height={128}
                                    style={null}
                                    className="w-full h-full m-auto overflow-hidden duration-1000 ease-in-out rounded-lg max-w-none"
                                    containerClassname="w-40 h-40 rounded-lg dark:bg-slate-50 dark:bg-opacity-5 bg-base-100 overflow-hidden flex relative mx-auto"
                                    loadingSpinnerClassName="absolute w-fit text-center top-[30%] left-0 right-0 m-auto z-[99999]"
                                    loadingSpinnerSvgClassName="w-4 h-4 inline"
                                    beforeLoadingTransition="scale-105 blur-sm grayscale"
                                    afterLoadingTransition="scale-100 blur-0 grayscale-0"
                                    imageUrl={logo_url}
                                />
                            ) : null}
                        </div>

                        <ClampTextComponent
                            className="!w-full !max-w-3xl overflow-hidden prose transition-all duration-200 ease-in-out line-clamp-7 animate-duration-500"
                            buttonClassName="dark:text-slate-300 text-current"
                            maxHeight={200}
                            text={description}
                        />
                    </div>
                ) : null}
                <div>
                    <div className="flex gap-3 max-sm:flex-col">
                        <div className="flex flex-col justify-between gap-4">
                            <div className="p-2 rounded-lg shadow bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400">
                                <h2 className="flex justify-center gap-1 my-2 text-xl font-semibold">
                                    <ClockIcon className="w-6 h-6 my-auto" />
                                    {BOOKING_PAGE_TEXT.WORKING_DAYS}
                                </h2>
                                <ul className="flex flex-col gap-1 px-3">
                                    {structuredWorkingHours.map((workingHour, index) => (
                                        <li className="flex justify-between gap-10" key={index}>
                                            <span className="flex-shrink-0 min-w-[85px] font-semibold">
                                                {workingHour.day}
                                            </span>
                                            <span>
                                                {workingHour.is_active ? (
                                                    `${splitTimeStringIntoHoursAndMinutes(workingHour.starting_hours)} - ${splitTimeStringIntoHoursAndMinutes(workingHour.ending_hours)}`
                                                ) : (
                                                    <span className="text-error">
                                                        {BOOKING_PAGE_TEXT.CLOSED_DAY}
                                                    </span>
                                                )}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="p-2 rounded-lg shadow bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400">
                                <h2 className="my-2 text-xl font-semibold text-center">
                                    {BOOKING_PAGE_TEXT.CONTACTS}
                                </h2>
                                {phone_number === '' || email === '' || website === '' ? (
                                    <div className="flex flex-col gap-2 px-3">
                                        {phone_number ? (
                                            <div className="flex gap-2">
                                                <PhoneIcon
                                                    className="w-6 h-6 my-auto"
                                                    strokeWidth={2}
                                                />

                                                <a href={`tel:+${phone_number}`}>+{phone_number}</a>
                                            </div>
                                        ) : null}
                                        {email ? (
                                            <div className="flex gap-2">
                                                <AtSymbolIcon
                                                    className="w-6 h-6 my-auto"
                                                    strokeWidth={2}
                                                />
                                                <a href={`mailto:${email}`}>{email}</a>
                                            </div>
                                        ) : null}
                                        {website ? (
                                            <div className="flex gap-2">
                                                <GlobeAltIcon className="w-6 h-6 my-auto" />
                                                <a href={website}>{website}</a>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                            {facebook_link !== '' ||
                            instagram_link !== '' ||
                            youtube_link !== '' ||
                            tiktok_link !== '' ||
                            linkedin_link !== '' ? (
                                <div className="p-2 rounded-lg shadow bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400">
                                    <h2 className="my-2 text-xl font-semibold text-center">
                                        {BOOKING_PAGE_TEXT.SOCIALS}
                                    </h2>
                                    <ul className="flex flex-wrap justify-center gap-2 py-1 mt-2">
                                        {facebook_link !== '' ? (
                                            <a href={facebook_link}>
                                                <FacebookIcon
                                                    className="w-6 h-6"
                                                    strokeWidth={1.5}
                                                />
                                            </a>
                                        ) : null}
                                        {instagram_link !== '' ? (
                                            <a href={instagram_link}>
                                                <InstagramIcon
                                                    className="w-6 h-6"
                                                    strokeWidth={1.5}
                                                />
                                            </a>
                                        ) : null}
                                        {youtube_link !== '' ? (
                                            <li>
                                                <a href={youtube_link}>
                                                    <YouTubeIcon
                                                        className="w-6 h-6"
                                                        strokeWidth={0.5}
                                                    />
                                                </a>
                                            </li>
                                        ) : null}
                                        {tiktok_link !== '' ? (
                                            <a href={tiktok_link}>
                                                <TikTokIcon className="w-6 h-6" strokeWidth={2} />
                                            </a>
                                        ) : null}
                                        {linkedin_link !== '' ? (
                                            <a href={linkedin_link}>
                                                <LinkedinIcon
                                                    className="w-6 h-6"
                                                    strokeWidth={0.5}
                                                />
                                            </a>
                                        ) : null}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                        <div className="flex-grow p-2 rounded-lg shadow bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400">
                            <h2 className="flex justify-center gap-1 my-2 text-xl font-semibold">
                                {BOOKING_PAGE_TEXT.LOCATION_HEADING}
                                <MapPinIcon className="w-6 h-6 my-auto" />
                            </h2>

                            <div className="flex flex-row justify-center gap-1">
                                <span className="px-1 font-semibold">
                                    {BOOKING_PAGE_TEXT.ADDRESS(address)}
                                </span>
                            </div>
                            <GoogleIframeMapComponent
                                iframeSrc={map_url}
                                width={width >= 1280 || width <= 1440 ? 400 : 600}
                                height={400}
                                className="border rounded-lg shadow max-xl:w-full border-opacity-80 border-slate-400 animate-fade animate-once animate-duration-250"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VenueInformationComponent
